<template>
  <div class="p-2 rounded">
    <vue-json-pretty :data="testinfo"></vue-json-pretty>
  </div>
</template>

<style scoped>
div {
  background: rgb(12, 12, 12);
}
</style>

<script>
import VueJsonPretty from 'vue-json-pretty';
import '@/assets/json_code.css';

export default {
  name: 'TestInfo',
  props: [
    'testinfo'
  ],
  components: {
    VueJsonPretty,
  },
}
</script>
