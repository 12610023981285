<template>
  <div class="settings_manufacturing_boxes">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='pipelines'" v-on:click="tab='pipelines'">Pipelines</b-nav-item>
      <b-nav-item v-if="loadedPipeline" :active="tab=='tests'" v-on:click="tab='tests'">{{ loadedPipeline.name }}</b-nav-item>
      <b-nav-item v-if="loadedPipeline && loadedTestinfo" :active="tab=='testinfo'" v-on:click="tab='testinfo'">{{ loadedTestinfo.id }}</b-nav-item>
    </b-nav>

    <pipelines v-if="tab=='pipelines'" @show="onPipeline"></pipelines>
    <tests v-if="loadedPipeline" :visible="tab=='tests'" :pipeline="loadedPipeline" @show="onTestinfo"></tests>
    <testinfo v-if="tab=='testinfo' && loadedTestinfo" :testinfo="loadedTestinfo"></testinfo>

  </div>
</template>

<script>
import pipelines from '@/components/manufacturing.tests/pipelines'
import tests from '@/components/manufacturing.tests/tests'
import testinfo from '@/components/manufacturing.tests/testinfo'

export default {
  name: 'Tests',
  components: {
    pipelines,
    tests,
    testinfo,
  },
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Manufacturing',
          to: '/manufacturing'
        },
        {
          text: 'Tests',
          active: true
        }
      ],
      loadedPipeline: null,
      loadedTestinfo: null
    }
  },
  watch: {
    tab() {
      if(this.tab == 'tests') this.loadedTestinfo = null
      if(this.tab != 'tests' && this.tab != 'testinfo') {
        this.loadedPipeline = null
        this.loadedTestinfo = null
      }
    }
  },
  methods: {
    onPipeline(data) {
      this.tab = 'tests'
      this.loadedPipeline = data
    },
    onTestinfo(data) {
      this.tab = 'testinfo'
      this.loadedTestinfo = data
    },
  },
  async created() {
    this.tab = 'pipelines'
  }
}
</script>
